<template>
  <div class="layout noheader">
    <transition @after-leave="afterLeave">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';

@Component({})
export default class NoHeader extends Vue {
  
  constructor() {
    super();
  }

  mounted() {
    this.$root.$emit('triggerScroll');
  }

  /*
   *  Transition afterLeave event
   *  We emit triggerScroll event to be catched by Router.scrollBehavior
   */
  afterLeave () {
    this.$root.$emit('triggerScroll');
  }

}
</script>